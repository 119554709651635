/* jshint esversion: 6 */
import service from './request'
//登录
export const Login = (data) =>{
    return service.post('/api/User/login',data)
}
//退出登录
export const Exit = (data) =>{
    return service.post('/api/User/logout')
}
//检测登录
export const getStuats = (data) =>{
    return service.post('/api/User/checkUserLogin')
}
//获取用户信息
export const getUserInfo = (data) =>{
    return service.post('/api/User/getUserInfo',data)
}
//注册
export const Register = (data) =>{
    return service.post('/api/User/registered',data)
}
//获取科目
export const getSubject = () =>{
    return service.post('/api/Question/getPaperConfig')
}
//获取地区
// 省市区
export const getProvinceList = (data) =>{
    return service.post('/api/Common/getProvinceList',data)
}

export const getCityList = (data) =>{
    return service.post('/api/Common/getCityList',data)
}

export const getDistrictList = (data) =>{
    return service.post('/api/Common/getDistrictList',data)
}
//获取考试中心详情
export const getPaperInfo =(data)=>{
    return service.post('/api/Center/getCenterInfo',data)
}
//获取考试列表
export const getPaperList = (data) =>{
    return service.post('/api/Question/getSignupPaperList',data)
}
// 获取真题试卷
export const getTestList =(data) =>{
    return service.post('/api/Question/getPaperList',data)
}
//获取试卷
export const getQuestion = (data) =>{
    return service.post('/api/Question/getQuestion',data)
}
//编程题答案
export const getResult = (data) =>{
    return service.post('/api/Question/getProgramResult',data)
}
//提交试卷
export const submitPaper = (data) =>{
    return service.post('/api/Question/submit',data)
}
//查看单个考试记录详情
export const viewScore = (data) =>{
    return service.post('/api/Question/getGradeInfo',data)
}
//查看准考证信息
export const getTicketInfo= () =>{
    return service.post('/api/User/getAdmissionTicket')
}
//获取组别
export  const  getGroupList =()=>{
    return service.post('/api/Question/getGroupList')
}
//获取公告
export const getNoticeList =(data)=>{
    return service.post('/api/index/getNoticeList',data)
}
// 获取试卷配置
export const getPaperConfig =()=>{
    return service.post('/api/Question/getPaperConfig')
}
// 获取试卷信息
export const getReportInfo = (data) =>{
    return service.post('/api/Question/getPaperInfo',data)
}
// 上传图片
export const upload = (data) =>{
    return service.post('/api/Common/upload',data)
}
// 上传手持准考证
export const uploadTicket = (data) =>{
    return service.post('/api/Room/report',data)
}
// 记录切屏次数
export const screeUpload = (data) =>{
    return service.post('/api/Room/screen',data)
}
//上传截图
export const screenShotUpload = (data) =>{
    return service.post('/api/Room/uploadScreenshot',data)
}
//设备信息
export const deviceInfo = (data) =>{
    return service.post('/api/Room/uploadDevice',data)
}
// 获取考试成绩
export const getScore = (data) =>{
    return service.post('/api/Question/getGradeInfo',data)
}
// 查看错题记录
export const getGradeQiestion = (data) =>{
    return service.post('/api/Question/getGradeQuestion',data)
}
// 限制考试 限制类型 1密码 2ip
export const checkRoom = (data) =>{
    return service.post('/api/Room/check',data)
}
