/* jshint esversion: 6 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    redirect:'/baseInfo',
    component: () => import('../views/exam-info'),
    children:[
      {
        path:'/baseInfo',
        name:'baseInfo',
        component:()=>import('../views/center/base-info')
      },
      {
        path:'/paper',
        name:'paper',
        component:()=>import('../views/center/competition-paper')
      },
      {
        path:'/test',
        name:'test',
        component:()=>import('../views/center/paper-test')
      },
      {
        path:'/registration',
        name:'registration',
        component:()=>import('../views/center/registration-info')
      },
      {
          path:'/examinationInfo',
          name:'examinationInfo',
          component:()=>import('../views/center/examination-info')
        },
    ]
  },
  {
    path: '/answer',
    name: 'answer',
    component:()=>import('../views/exam-center')
  },
  //新版
  {
      path: '/answer_new',
      name: 'answer_new',
      component:()=>import('../views/exam-center-new')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import(/* webpackChunkName: "about" */ '../views/register')
  },
  {
    path: '/examInfo',
    name: 'examInfo',
    component: () => import(/* webpackChunkName: "about" */ '../views/exam-info')
  },
  {
    path: '/score',
    name: 'score',
    component: () => import(/* webpackChunkName: "about" */ '../views/score')
  },
  {
    path: '/analysis',
    name: 'analysis',
    component: () => import(/* webpackChunkName: "about" */ '../views/analysis')
  },
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
//保存下来push方法 将来我们还会用到原push方法进行路由跳转
let originPush=VueRouter.prototype.push;

//接下来我们重写push|replace方法
//第一个参数location参数像原push方法声明跳转的地方 resolve和reject传递成功与失败

VueRouter.prototype.push=function(location,resolve,reject){
  if(resolve && reject){
    //如果成功 调用原来的push方法
    originPush.call(this,location,resolve,reject);
  }else{
    originPush.call(this,location,()=>{},()=>{}); }
}
export default router

router.beforeEach((to, from, next) => {
  // 1.如果访问的是登录页面（无需权限），直接放行
  if (to.path === "/login" || to.path ==='/register')
    return next();
  // 2.如果访问的是有登录权限的页面，先要获取token
  const tokenStr = localStorage.getItem("ctoken");
  // 2.1如果token为空，强制跳转到登录页面；否则，直接放行
  if (!tokenStr) {
    return next("/login");
  }
  next();
});
router.afterEach(() => { document.body.scrollTop = 0; document.documentElement.scrollTop = 0;})
