<template>
  <div class="lang-change" v-if="!($route.path=='/answer_new')">
    <div class="label">语言选择：</div>
<!--    <el-dropdown trigger="click" @command="changeLanguage">-->
<!--      <div>-->
<!--        <img class="change-font" :src="require('@/images/'+$i18n.locale+'.png')" alt="">-->
<!--      </div>-->
<!--      <el-dropdown-menu slot="dropdown">-->
<!--        <el-dropdown-item command="cn" :disabled="'cn'=== $i18n.locale ">{{$t('cnlang')}}</el-dropdown-item>-->
<!--        <el-dropdown-item command="tw" :disabled="'tw'=== $i18n.locale ">{{$t('twlang')}}</el-dropdown-item>-->
<!--      </el-dropdown-menu>-->
<!--    </el-dropdown>-->
    <el-select v-model="value" placeholder="请选择" style="width: 150px;" @change="changeLanguage">
      <el-option
          v-for="item in langs"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        <span style="float: left">{{ item.label }}</span>
      </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  data(){
    return{
      langs: [{
        value: 'cn',
        label: this.$t('cnlang')
      },{
        value: 'tw',
        label: this.$t('twlang')
      },{
        value: 'en',
        label: this.$t('enlang')
      }],
      value:''
    }
  },
  mounted() {
    this.value=localStorage.getItem('font')||'tw'
  },
  methods: {
    changeLanguage(lang) {
      this.value=lang
      //自己引入语言包语言切换
      this.$root.$i18n.locale = lang
      //存储本地
      localStorage.setItem('font',lang)
      window.location.reload(true);
    }
  }
}
</script>
<style lang="scss" scoped>
.lang-change{
  display: flex;
  align-items: center;
  font-size: 20px;
  ::v-deep{
    .el-input{
      font-size: 18px;
    }
    .el-select-dropdown__item span{
      font-size: 18px !important;
    }
  }
}
.change-font{
  width: 42px;
  height: 38px;
  margin-left: 20px;
}
</style>
