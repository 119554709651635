import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
//引入element
import 'element-ui/lib/theme-chalk/index.css';
import '../public/element'
import './utils/directive'
// 转base64
const Base64 = require('js-base64').Base64
//切换语言
import locale from 'element-ui/lib/locale';
import VueI18n from 'vue-i18n'
// 引入自己的语言包
import tw from './langs/tw';
import cn from './langs/cn';
import en from './langs/en';
// 引入element语言包
import cnLocale from 'element-ui/lib/locale/lang/zh-CN'
import twLocale from 'element-ui/lib/locale/lang/zh-TW'
import enLocale from 'element-ui/lib/locale/lang/en'
// 兼容element
locale.i18n((key, value) => i18n.t(key, value))
Vue.use(VueI18n)
const messages = {
    cn: Object.assign(cn, cnLocale),
    tw: Object.assign(tw, twLocale),
    en: Object.assign(en, enLocale),
}

const i18n = new VueI18n({
  locale:localStorage.getItem('font') || 'tw', // 设置默认语言
  messages
})
import Lang from '@/components/lang'
Vue.component('Lang', Lang)
Vue.config.productionTip = false

Vue.directive('dialogDrag',{
  bind(el,binding,vnode){
    console.log(el);
  }
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
