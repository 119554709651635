module.exports = {
    cnlang: '中文简体',
    twlang: '中文繁体',
    enlang: 'English',
    tuichu: '退出登录',
    qxdl:'请先登录',
    denglu: '登录',
    zhanghao: '登录账号',
    wdl:'请先登录',
    zhplaceholder:'请输入登录账号',
    mima: '登录密码',
    mmplaceholder:'请输入登录密码',
    cjcx:'成绩查询及证书验证',
    dlcg:'登录成功',
    jsdh:'技术服务电话',
    kaoshi: '川渝港澳青少年科学大赛',
    gnts:'功能调试',
    djsc:'点击上传准考证',
    sczkz:'请先上传准考证',
    sxtts:'摄像头调试',
    sczkz:'手持准考证拍摄',
    sxtdk:'摄像头已打开',
    djps:'点击拍摄',
    sc:'上传',
    qxkj:'请考生手持【准考证】拍摄,确保人像和准考证信息清晰可见',
    kdsxt:'看到视频图像即已打开摄像头',
    bjr:'没有开启摄像头权限或浏览器版本不兼容',
    dksxt:'请先打开摄像头',
    sccg:'上传成功，开始考试吧',
    myzp:'未发现照片',
    dk:'打开',
    js:'结束',
    ggzx:'公告中心',
    zysx:'注意事项',
    jbxx:'基本信息',
    bssj:'比赛试卷',
    ztlx:'真题练习',
    ckzkz:'查看准考证',
    zkzh:'准考证号',
    qx:'取消',
    xzzkz:'下载准考证',
    nf:'年份',
    xm:'姓名',
    xb:'性别',
    nj:'年级',
    zb:'组別',
    km:'科目',
    cslj:'参赛链接',
    kc:'考场',
    sfzh:'身份证号',
    kssj:'考试时间',
    sx:'赛项',
    tmlx:'题目类型',
    kssc:'考试时长',
    kszf:'考试总分',
    tmsl:'题目数量',
    fz:'分钟',
    zhong:'钟',
    zwsj:'暂无试卷',
    qks:'去考试',
    ksz:'考试中',
    yjj:'已交卷',
    ljks:'立即考试',
    ljlx:'立即练习',
    kswks:'考试未开始',
    ksyjs:'考试已结束',
    ksjsl:'考试还未开放，去看看其他练习吧，是否前往?',
    kswkf:'考试暂未开放',
    ts:'提示',
    ksjs:'考试结束,成绩待通知',
    iknow:'我知道了',
    ksxx:'考试信息',
    ksmc:'考试名称',

    ksdjs:'倒计时',
    tishu:'题数',
    zf:'总分',
    jj:'交卷',
    dtk:'答题卡',
    ckda:'查看答案',
    cktm:'查看题目',
    timu:'题目',
    syt:'上一题',
    xyt:'下一题',
    dt:'答题',
    ksdt:'开始答题',
    qd:'确 定',
    gb:'关 闭',
    qdlk:'当前页面数据未保存，确定要离开?',
    gbts:'请点击保存，若继续将不保存答案,是否继续退出？',
    ksks:'开始考试，全程录像，退出成绩将作废',
    yxts: '您还没有答题哦，请先答题再运行',
    jjts:'距离考试结束还有5分钟，请仔细检查后交卷，考试结束将自动交卷',
    zuihou:'已经是最后一道题啦,请仔细检查后交卷',
    dyt:'已经是第一题啦',
    save:'保存成功',
    nhy:'您还有',
    sfjj:'个题没有作答, 是否继续提交试卷?',
    qdjj:'确定交卷',
    zjcyx:'再检查一下',
    yqx:'已取消交卷,请继续作答',
    ddcj:'已交卷,成绩待通知',
    yxsj:'运行时间',
    yxjg:'运行结果',
    tiaoshi:'调试',
    wjdx:'文件大小',
    srz:'请输入需要输入的值并使用空格隔开',


    fhdb:'返回顶部',
    answer:'您的答案',
    zqanswer:'正确答案',
    explain:'答案解析',
    none:'无',
    ti:'题',
    wdt:'未答题',
    single:'单选题',
    multi:'多选题',
    judge:'判断题',
    simple:'简答题',
    scratch:'图形化题',
    program:'编程题',

    hgfsx:'合格分数线',
    dtsj:'答题时间',
    ndcj:'您的成绩',
    tgks:'恭喜顺利通过本次考试',
    wtg:'未通过考试，下次继续努力',
    dfxq:'得分详情',
    tx:'题型',
    ddts:'答对题数',
    zfs:'总分数',
    df:'得分',
    bcksg:'本次考试共',
    dd:'答对',
    dedao:'得到',

    yzm:'验证码',
    sryzm:'请输入验证码',
    ksts:'当前试卷语言为',
    sfks:'确定开始考试？开始考试后将不允许修改'
}
