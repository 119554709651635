/* jshint esversion: 6 */
import Vue from "vue";
import {Form,FormItem,Button, Pagination, CheckboxGroup, Checkbox, RadioGroup,
Radio, Table, TableColumn, Image, Message,Input,MessageBox,Dialog,Select,Option,
Row,Col,Descriptions,DescriptionsItem,Notification,Empty,Dropdown,DropdownMenu,
DropdownItem,Loading } from "element-ui";

Vue.use(Button)
Vue.use(Pagination)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Image)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Dialog)
Vue.use(Select)
Vue.use(Option)
Vue.use(Row)
Vue.use(Col)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
Vue.use(Empty)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
Vue.use(Loading);
Vue.use(Loading.directive);

Vue.prototype.$message = Message
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$notify = Notification;

