/* jshint esversion: 6 */
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token:''
  },
  getters: {
  },
  mutations: {
    loginSuccess(state,val){
      state.token=val
    }
  },
  actions: {
  },
  modules: {
  }
})
