module.exports = {
    cnlang:'中文簡體',
    twlang:'中文繁體',
    enlang: 'English',
    tuichu: '退出登錄',
    qxdl:'請先登錄',
    denglu: '登錄',
    zhanghao: '登錄賬號',
    wdl:'請先登錄',
    zhplaceholder:'請輸入登錄賬號',
    mima: '登錄密碼',
    mmplaceholder:'請輸入登錄密碼',
    cjcx:'成績查詢及證書驗證',
    jsdh:'技術服務電話',
    dlcg:'登錄成功',
    kaoshi:'川渝港澳青少年科學大賽',
    gnts:'功能調試',
    sxtts:'攝像頭調試',
    djsc:'點擊上傳准考證',
    sczkz:'請先上傳准考證',
    sxtdk:'攝像頭已打開',
    sczkz:'手持准考證拍攝',
    djps:'點擊拍攝',
    sc:'上傳',
    qxkj:'請考生手持【准考證】拍攝,確保人像和准考證信息清晰可見',
    kdsxt:'看到視頻圖像即已打開攝像頭',
    bjr:'沒有開啟攝像頭權限或瀏覽器版本不兼容',
    dksxt:'請先打開攝像頭',
    sccg:'上傳成功，開始考試吧',
    myzp:'未發現照片',
    dk:'打開',
    js:'結束',
    ggzx:'公告中心',
    zysx:'註意事項',
    jbxx:'基本信息',
    bssj:'比賽試卷',
    ztlx:'真題練習',
    ckzkz:'查看准考證',
    zkzh:'准考證號',
    qx:'取消',
    xzzkz:'下載准考證',
    nf:'年份',
    xm:'姓名',
    xb:'性别',
    nj:'年級',
    zb:'組別',
    km:'科目',
    cslj:'參賽鏈接',
    kc:'考場',
    sfzh:'身份證號',
    kssj:'考試時間',
    sx:'賽項',
    tmlx:'題目類型',
    kssc:'考試時長',
    kszf:'考試總分',
    tmsl:'題目數量',
    fz:'分鐘',
    zhong:'鐘',
    zwsj:'暫無試卷',
    qks:'去考試',
    ksz:'考試中',
    yjj:'已交卷',
    ljks:'立即考試',
    ljlx:'立即練習',
    kswks:'考試未開始',
    ksyjs:'考試已結束',
    ksjsl:'考試還未開始，去看看其他練習吧，是否前往?',
    ts:'提示',
    ksjs:'考試結束,成績待通知',
    iknow:'我知道了',
    ksxx:'考試信息',
    ksmc:'考試名稱',

    ksdjs:'倒計時',
    tishu:'題數',
    zf:'總分',
    jj:'交卷',
    dtk:'答題卡',
    ckda:'查看答案',
    cktm:'查看題目',
    timu:'題目',
    syt:'上一題',
    xyt:'下一題',
    dt:'答題',
    ksdt:'開始答題',
    qd:'確 定',
    gb:'關 閉',
    qdlk:'當前頁面數據未保存，確定要離開?',
    gbts:'請點擊保存，若繼續將不保存答案,是否繼續退出？',
    ksks:'開始考試，全程錄像，退出成績將作廢',
    yxts: '您還沒有答題哦，請先答題再運行',
    jjts:'距離考試結束還有5分鐘，請仔細檢查後交卷，考試結束將自動交卷',
    zuihou:'已經是最後一道題啦,請仔細檢查後交卷',
    dyt:'已經是第一題啦',
    save:'保存成功',
    nhy:'您還有',
    sfjj:'個題沒有作答, 是否繼續提交試卷?',
    qdjj:'確定交卷',
    zjcyx:'再檢查一下',
    yqx:'已取消交卷,請繼續作答',
    ddcj:'已交卷,成績待通知',
    yxsj:'運行時間',
    yxjg:'運行結果',
    tiaoshi:'調試',
    wjdx:'文件大小',
    srz:'請輸入需要輸入的值並使用空格隔開',


    fhdb:'返回頂部',
    answer:'您的答案',
    zqanswer:'正確答案',
    explain:'答案解析',
    none:'無',
    ti:'題',
    wdt:'未答題',
    single:'單選題',
    multi:'多選題',
    judge:'判斷題',
    simple:'簡答題',
    scratch:'圖形化題',
    program:'編程題',

    hgfsx:'合格分數線',
    dtsj:'答題時間',
    ndcj:'您的成績',
    tgks:'恭喜順利通過本次考試',
    wtg:'未通過考試，下次繼續努力',
    dfxq:'得分詳情',
    tx:'題型',
    ddts:'答對題數',
    zfs:'總分數',
    df:'得分',
    bcksg:'本次考試共',
    dd:'答對',
    dedao:'得到',
    yzm:'驗證碼',
    sryzm:'請輸入驗證碼',

    ksts:'當前試卷語言為',
    sfks:'考試開始將不允許修改,確定開始考試？'
}
